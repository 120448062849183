class Delegate {
    constructor() {
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);
        this.raise = this.raise.bind(this);
        this.clear = this.clear.bind(this);
    }

    listeners = [];

    //Adds a delegate handler function 
    add(listener) {
        //Avoid duplicates
        for (let i = 0; i < this.listeners.length; ++i) {
            if (this.listeners[i] === listener) return;
        }
        this.listeners.push(listener);
    }

    //Removes a delegate handler function
    remove(listener) {
        this.listeners = this.listeners.filter((x) => x !== listener);
    }

    //Raise a delegate event with arguments
    raise(...args) {
        this.listeners.slice(0).forEach((x) => x(...args));
    }

    clear() {
        this.listeners = [];
    }

    //Gets the count of listeners
    get count() {
        if (this.listeners) {
            return this.listeners.length;
        }

        return 0;
    }
}

class PWWebsocketSDK {

    constructor() {
        //Bind "this" context to all methods
        this.addMessageListener = this.addMessageListener.bind(this);
        this.removeMessageListener = this.removeMessageListener.bind(this);
        this.raiseMessageListener = this.raiseMessageListener.bind(this);
        this.handleWebsocketMessage = this.handleWebsocketMessage.bind(this);
        this.connectToGameServer = this.connectToGameServer.bind(this);
        this.sendWebsocketMessage = this.sendWebsocketMessage.bind(this);
    }

    //Type Map<string, Delegate>
    messageListeners = new Map();

    websocket;

    //Delegate for all messages
    OnMessage = new Delegate(); //<{ message: string, messageData: string}>

    addMessageListener(messageName, listener) {
        let delegate = this.messageListeners.get(messageName);
        if (!delegate) {
            delegate = new Delegate();
            this.messageListeners.set(messageName, delegate);
        }
        delegate.add(listener);
    }

    removeMessageListener(messageName, listener) {
        let delegate = this.messageListeners.get(messageName);
        if (delegate) {
            delegate.remove(listener);

            if (delegate.count == 0) {
                this.messageListeners.delete(messageName);
            }
        }
    }

    raiseMessageListener(messageName, messageData) {
        //Raise specific listeners
        let delegate = this.messageListeners.get(messageName);
 
        if (delegate) {
            delegate.raise(messageData);
        }
    }

    handleWebsocketMessage(wsMsg) {
        let msgBody = JSON.parse(wsMsg.data).data;

        const message = msgBody.message;
        const messageData = msgBody.messageData;

        console.log("Websocket message received:", message, messageData);

        console.log(this.websocket);

        //Raise generic message listener
        this.OnMessage.raise({message, messageData});

        this.raiseMessageListener(message, messageData);
    }

    connectToGameServer(url, serverID, playerID, autoReconnect = true) {
        this.websocket = new WebSocket(
            `${url}?playerID=${playerID}&serverID=${serverID}&isServer=false`
        )

        this.websocket.onopen = () => {
            console.log("Connected to websocket");

            this.websocket.onmessage = this.handleWebsocketMessage;
        }

        this.websocket.onerror = () => {
            console.log("Error connecting to websocket");
        }

        this.websocket.onclose = () => {
            console.log("Websocket closed");
        }
    }

    sendWebsocketMessage(message, messageData, action = "PlayerMessage") {
        console.log("Sending message:", message, messageData);

        let messageDataToSend = messageData;

        //Only stringify messageData if it is not a string
        if (typeof messageData !== "string") {
            messageDataToSend = JSON.stringify(messageData);
        }

        try {
            this.websocket.send(
                JSON.stringify({
                    action,
                    body: {
                        data: {
                            message,
                            messageData: messageDataToSend
                        },
                    },
                })
            );
        } catch (error) {
            console.log("Error sending websocket message:", error);
        }
        
    }
}

export default PWWebsocketSDK;