import React, { useEffect, useRef } from "react";
import { useSwipeVector } from "use-swipe-hook";

import styles from "./SwipeArea.module.css";

export default function SwipeArea(props) {

  const swipeContainerRef = useRef(null);

  const {
    magnitude,
    direction,
    origin: { x, y }
  } = useSwipeVector({
    ref: swipeContainerRef,
    thresholdPX: 5,
    unit: "deg",
    useRelativeUnits: true
  })

  useEffect(() => {
    if (magnitude !== 0) {
      props.handleSwipe(magnitude, direction);
    }
  }, [magnitude, direction])

  const handleDrag = (e) => {
    e.preventDefault();
  }
  
  return (
    <div className={styles.swipeContainer} ref={swipeContainerRef} onDrag={handleDrag}>
      <p>Swipe Here</p>
    </div>
  ); 
}
