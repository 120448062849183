import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";

import PWWebsocketSDK from "./scripts/pw__websocket_sdk";
import SwipeArea from "./components/SwipeArea/SwipeArea";

import "./App.css";

function App() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [serverNumber, setServerNumber] = useState(1);
  const [connected, setConnected] = useState(false);

  //Instantiate SDK
  const pwWebsocketSDK = useRef(new PWWebsocketSDK());

  useEffect(() => {
    //Apply unfocus listener to all inputs
    let inputs = document.getElementsByClassName("formInput");

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("blur", function () {
        window.scrollTo(0, 0);
      });
    }
  }, []);

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleServerNumberChange = (e) => {
    setServerNumber(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    //Connect to the websocket
    try {
      pwWebsocketSDK.current.connectToGameServer("ws://3.86.42.46:8080", serverNumber, uuidv4());

      //Set up listeners
      pwWebsocketSDK.current.addMessageListener("RequestName", handleSendNameRequestResponse)

      setConnected(true);

      //Scroll to top of page
      window.scrollTo(0, 0);

      const appHolder = document.getElementById("appHolder");
      appHolder.classList.add("appHolderSwipe");
    } catch (error) {
      console.log("Error connecting to websocket:", error);
    }
  };

  const handleSendNameRequestResponse = (messageData) => {
    //Send back name request response
    pwWebsocketSDK.current.sendWebsocketMessage(
      "NameResponse",
      {
        firstName,
        lastName
      }
    )
  };

  const handleSwipe = (mag, dir) => {
    console.log("SWIPED:", mag, dir);
    pwWebsocketSDK.current.sendWebsocketMessage(
      "Swipe",
      {
        mag,
        dir,
      }
    )
  };

  return (
    <div className="App">
      <div className="appHolder" id="appHolder">
        {connected ? (
          <>
            {/* <h1>You have pressed the button {count} times.</h1>

            <button onClick={handleButtonClick}>Click Me!</button> */}

            <SwipeArea handleSwipe={handleSwipe} />
          </>
        ) : (
          <div>
            <form className="form" onSubmit={handleFormSubmit}>
              <label htmlFor="serverNumber">Server ID</label>
              <input
                type="number"
                onChange={handleServerNumberChange}
                id="serverNumber"
                className="formInput"
              />

              <label htmlFor="firstName">First Name</label>
              <input
                onChange={handleFirstNameChange}
                id="firstName"
                className="formInput"
              />

              <label htmlFor="lastName">Last Name</label>
              <input
                onChange={handleLastNameChange}
                id="lastName"
                className="formInput"
              />

              <button onClick={handleFormSubmit}>Connect</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
